.contacts
	display: flex
	flex-direction: column
	align-items: center
	width: 100vw
	padding-bottom: 0px
	h1
		visibility: visible
	.contact__item
		display: flex
		flex-direction: column
		width: 100%
		height: 100%
		&__text
			.flex__item
				@media (min-width: 20rem)
					width: 100%
				@media (min-width: 40rem)
					width: 35%
				@media (min-width: 70rem)
					width: 19%
			display: flex
			flex-direction: row
			flex-wrap: wrap
			flex-grow: 0
			justify-content: center
			margin-bottom: 20px
			width: 100%
			&__tel
				margin: 10px
				& > h2
					text-align: center
				&__num
					text-align: center
					.tel-life 
						display: inline-block 
						position: relative 
						background-image: url(/assets/img/svg/tel-life.svg) 
						background-position: center 
						background-size: cover 
						background-repeat: no-repeat 
						width: 20px 
						height: 20px 
						margin-right: 5px 
						top: 5px 
					.tel-ks 
						display: inline-block 
						position: relative 
						background-image: url(/assets/img/svg/tel-ks.svg) 
						background-position: center 
						background-size: cover 
						background-repeat: no-repeat 
						width: 20px 
						height: 20px 
						margin-right: 5px 
						top: 5px 
			&__email
				margin: 10px 
				text-align: center 
				& > h2 
					text-align: center 
			&__adress 
				margin: 10px 
				text-align: center 
				& > h2 
					text-align: center 
			&__socialmedia
				display: flex 
				flex-direction: column 
				margin: 10px 
				text-align: center 
				& > h2 
					text-align: center 
					color: var(--wite) 
				svg 
					width: 30px
					height: 30px
					fill: var(--wite)
				&__icon 
					display: flex 
					flex-wrap: wrap 
					flex-direction: row 
					justify-content: space-evenly 
					a
						cursor: pointer 
						text-align: center 
						transition: all .18s ease-in-out
						&:hover
							transform: scale(1.2)
							transition: all .18s ease-in-out

					img 
						width: 2rem 
						height: 2rem 
		&__map 
			display: flex 
			margin: 0 0 
			padding: 0 0 
			height: 400px 
			iframe
				border-radius: 1rem
.copyright 
	text-align: center 
	justify-content: center 
	color: var(--wite)
	width: 100%
	margin: 10px 0px
	font-family: "ElMessiri-c"
