// .sidebar
// 	position: absolute
// 	width: 100%
// 	height: 100%
// 	background-color: white
// 	z-index: 9999
.hero
	width: 100vw
	height: 100vh
	box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
	&::before
		content: ' '
		display: block
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		opacity: .9
		background-image: url(/assets/img/hero_bg.jpg)
		background-position: center
		background-size: cover
		background-repeat: no-repeat
		z-index: -999
	.menu__wrapper.active
		transform: translate(0, var(--delta-height))
		transition-duration: 1s
	.menu__wrapper
		position: fixed
		font-family: JostRegular-c
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		width: 100%
		background-color: var(--blue-a)
		z-index: 999 
		box-shadow: 0 0px 40px 20px var(--blue-s)
		backdrop-filter: blur(10px)
		background-image: none
		transform: translate(0, 0)
		transition-duration: 1s
		.wrapper__tel__menu.visible
			display: none
		.wrapper__tel__menu
			width: 100%
			.tel__wrapper
				width: 100%
				font-family: JostRegular-c
				display: flex
				justify-content: center
				border-bottom: solid 1px var(--yellow)
				border-color: var(--yellow)
				/*rgba(253, 253, 253, 0.4)*/
				/* text-shadow: 1px 1px 2px black, 0 0 1em rgb(253, 253, 253), 0 0 0.2em rgb(255, 255, 255); */
				%alink
					font-size: 18px
					margin: 10px 20px
					padding: 0px 20px
					padding-left: 0
					margin-left: 5px
					cursor: pointer
					text-transform: uppercase
					&:hover
						color: var(--yellow) !important
				%tel-operator
					display: inline-block
					position: relative
					background-position: center
					background-size: cover
					background-repeat: no-repeat
					width: 20px
					height: 20px
					top: 3px
				a
					margin-right: 10px
				svg
					width: 25px
					height: 25px
					fill: var(--wite)
				.tel
					.tel-life
						@extend %tel-operator
						background-image: url(/assets/img/svg/tel-life.svg)
					.tel-ks
						@extend %tel-operator
						background-image: url(/assets/img/svg/tel-ks.svg)
					a
						@extend %alink
				@media (min-width: 20rem)
					//flex-direction: column
					flex-wrap: wrap
					text-align: center
					.tel a
						padding: 0 0
				@media (min-width: 40rem)
					flex-direction: row
					.tel a
						font-size: 18px
					.tel .tel-life, .tel .tel-ks
						width: 20px
						height: 20px
			.menu
				color: antiquewhite
				text-decoration: none
				font-family: JostRegular-c
				display: flex
				flex-direction: row
				flex-wrap: wrap
				justify-content: center
				align-items: center
				padding-top: 10px
				padding-bottom: 10px
				> a
					font-size: 18px
					padding: 0px 20px
					cursor: pointer
					color: rgb(255, 255, 255) !important
					text-transform: uppercase
					transition: all .18s ease-in-out
					&:hover
						color: var(--yellow) !important
						font-size: 18px
						text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.9)
						transform: scale(1.08)
						transition: all .18s ease-in-out
						/* text-decoration: underline */
						/* text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(253, 253, 253), 0 0 0.2em rgb(255, 255, 255) */
						/* animation: 0.5s linear 0s infinite normal hover */
						z-index: 999
					@keyframes hover
						0%
							text-shadow: 0 0 .0em rgb(253, 253, 253), 0 0 .0em rgb(255, 255, 255)
						100%
							text-shadow: 0 0 .1em rgb(253, 253, 253), 0 0 .2em rgb(255, 255, 255)
				@media (min-width: 20rem)
					flex-direction: column
					a
						font-size: 18px
				@media (min-width: 40rem)
					flex-direction: row
					a
						font-size: 18px
		.sidebarbtn__wrapper
			line-height: 1
			transform: scale(0.3)
			height: 30px
			.flex-wrapper
				display: flex
				flex-direction: row
				flex-wrap: nowrap
				justify-content: center
				align-items: center
				text-align: center
				transform: translate(24px,-12px)
				.text
					transform: scale(3.5)
					margin-right: 90px
				.icon
					margin: 0 auto
					width: 50px
					height: 50px
					cursor: pointer
					position: relative
					user-select: none
					margin-bottom: 5px
					.first, .last
						width: 50px
						animation: scales 2s
						.left
							width: 50%
							height: 6px
							background: white
							display: inline-block
							margin-right: -4px
							border-radius: 5px 0 0 5px
							position: relative
							transition: all 2s
						.right
							width: 50%
							height: 6px
							background: white
							display: inline-block
							margin-right: -4px
							border-radius: 0 5px 5px 0
							position: relative
							transition: all 2s
					.second
						width: 50px
						height: 0px
						display: inline-block
						border: 3px solid white
						box-sizing: border-box
						border-radius: 5px
						animation: scales 2s
				> .active
					padding-top: 5px
					box-sizing: border-box
					.first
						animation: scales 2s
						.left
							transform: rotate(45deg) scale(1.2)
							left: 4px
							top: 1px
							border-radius: 5px
						.right
							transform: rotate(-45deg) scale(1.2)
							right: 4px
							top: 2px
							border-radius: 5px
					.second
						border: 5px solid white
						width: auto
						height: auto
						position: absolute
						top:-14px
						bottom: -16px
						left: -15px
						right: -15px
						border-radius: 50%
						animation: circle 2s
					.last
						animation: scales 2s
						.left
							transform: rotate(-45deg) scale(1.2)
							left: 4px
							top: 2px
							border-radius: 5px
						.right
							transform: rotate(45deg) scale(1.2)
							right: 4px
							top: 1px
							border-radius: 5px
				@keyframes circle
					0%
						opacity: 0
						transform: scale(0)
					60%
						opacity: 1
						transform: scale(1.1)
					70%
						transform: scale(1)
					100%
						opacity: 1
						transform: scale(1)
				@keyframes scales
					0%
						transform: scale(0.3)
					30%
						transform: scale(1.2)
					60%
						transform: scale(0.9)
					100%
						transform: scale(1)
	.logo__wrapper
		font-family: ElMessiri-c
		display: flex
		justify-content: center
		align-items: center
		width: 100%
		height: 100%
		.logo
			text-align: center
			.logo__lable
				font-family: ElMessiri-c
				color: white
				font-size: 144px
				position: relative
				text-transform: none
				text-shadow: 1px 1px 2px black, 0 0 1em rgb(253, 253, 253), 0 0 0.2em rgb(255, 255, 255)
				/* top: -1rem; */
			.logo__text
				top: -40px
				font-family: ElMessiri-c
				color: white
				font-size: 68px
				line-height: 0
				position: relative
				text-transform: none
				text-shadow: 1px 1px 2px black, 0 0 1em rgb(253, 253, 253), 0 0 0.2em rgb(255, 255, 255)
			@media (min-width: 20rem)
				.logo__lable
					font-size: calc(144px*.75)
					line-height: 1.8
				.logo__text
					font-size: calc(68px*.75)
			@media (min-width: 40rem)
				.logo__lable
					font-size: calc(144px*1)
					line-height: 1.6
				.logo__text
					font-size: calc(68px*1)
			@media (min-width: 70rem)
				.logo__lable
					font-size: calc(144px*1.5)
					line-height: 1.5
				.logo__text
					font-size: calc(68px*1.5)
	.sidebarbtn
		visibility: visible


