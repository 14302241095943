.price
	#transcroller-body
		.cards
			display: flex
			list-style: none
			margin: 0
			padding: 0 0
			justify-content: space-evenly
			flex-wrap: wrap
			.card__item
				display: flex
				padding: 1rem
				justify-content: center
				@media (min-width: 20rem)
					.card__item
						width: 100%
				@media (min-width: 40rem)
					.card__item
						width: 50%
				@media (min-width: 70rem)
					.card__item
						width: 33.3333%
				.card
					color: var(--c27)
					background-color: var(--c13)
					border-radius: 1rem
					box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
					display: flex
					flex-direction: column
					overflow: hidden
					width: 20rem
					height: 25rem
					margin-bottom: 50px
					margin-left: 10px
					margin-right: 10px
					min-width: 20rem
					&:hover 
						.card__image
							filter: contrast(100%)
							transform: scale(1.1)
						.card__price
							transform: scale(1.2)
					.card__image
						width: 100%
						height: 50%
						background-position: center
						background-repeat: no-repeat
						background-size: contain
						border-top-left-radius: 1rem
						border-top-right-radius: 1rem
						filter: contrast(70%)
						overflow: hidden
						position: relative
						transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91)
						transition: transform 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91)
						::before
							content: ""
							display: block
							padding-top: 56.25%
					.card__image--al
						background-image: url(/assets/img/price/al.png)
					.card__image--cu
						background-image: url(/assets/img/price/med.png)
					.card__image--cuzn
						background-image: url(/assets/img/price/lat.png)
					.card__image--fe
						background-image: url(/assets/img/price/chermet.png)
					.card__image--nerj
						background-image: url(/assets/img/price/nerj.png)
					.card__image--zn
						background-image: url(/assets/img/price/zn.png)
					.card__content
						display: flex
						flex: 1 1 auto
						flex-direction: column
						padding: 1rem
						.card__title
							color: var(--c27)
							font-size: 24px
							letter-spacing: 2px
							text-transform: uppercase
							text-align: center
							padding: 0

						.card__text
							color: var(--c27)
							text-align: center
							padding: 0

						.card__price
							text-align: center
							padding: 0
							font-size: 40px
							transition: transform 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91)
							text-shadow: 1px 1px 2px black, 0 0 1em white, 0 0 0.2em white
						.card__unit
							color: var(--c27)
							text-align: center
							padding: 0