.services
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	#transcroller-body
		.services__item
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			width: 100%
			height: 100%
			@media (min-width: 20rem)
				flex-direction: column
			@media (min-width: 40rem)
				flex-direction: row
			.services__padding
				width: 30px
			.service__img	
				width: 50%
				height: 100%
				background-position: center
				background-repeat: no-repeat
				background-size: cover
				border-radius: 1rem
				box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
				overflow: hidden
				position: relative
				margin-top: 12px
				&::before
					content: " "
					display: block
					padding-top: 100%
					width: 100%
					height: 100%
				@media (min-width: 20rem)
					visibility: visible
					width: 100%
				@media (min-width: 40rem)
					visibility: visible
					width: 50%
			.services__text
				width: 50%
				height: calc(100vh*0.8)
				font-size: 18px
				overflow: scroll
				@media (min-width: 20rem)
					width: 100%
				@media (min-width: 40rem)
					width: 50%
				p
					color: var(--wite)
	.services__img__demontaj
		background-image: url(../img/services/demontaj.jpg)
	.services__img__vivoz
		background-image: url(../img/services/vivoz.jpg)


