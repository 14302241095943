:root
	--wite: rgb(255,255,255)
	--black: rgba(0,0,0,1)
	--yellow: rgb(255, 215, 0)
	--blue: rgb(105, 105, 105)
	--blue-a: rgba(0, 0, 0, 0.5)
	--blue-s: rgba(0, 0, 0, 0.5)
	--index: calc(1vw+1vh)
	--color-header: #f4efec
	--color-text: #cdc6c3
	--red: rgb(255 0 0)
	--delta-height: 0px
	--bg-gradient: linear-gradient(180deg, 	#372952, #2c495b 100%)
	//--bg-gradient: linear-gradient(180deg, 	#5e3800 10%, #003d79 25%, #c4a600 75%, #424242 95%)