#sale.sale
	width: 100%
	img
		width: 100%
		align-content: center
		opacity: .7
	p
		font-size: 18px
		color: var(--wite)
		.link
			color: var(--yellow)
			font-size: 24px
