html
	scroll-behavior: smooth
	font-size: 16px
	background-color: (--black)

h1, h2, h3, h4, h5, h6
	font-family: "ElMessiri-c"

h1
	text-transform: uppercase
	color: var(--wite)
	text-shadow: 0px 10px 15px rgba(0, 0, 0, .5)
h2
	text-transform: uppercase
	color: var(--wite)

a
	color: var(--wite)
	text-decoration: none
	cursor: pointer

ul, li
	font-family: JostRegular-c
	text-align: justify
	text-justify: inter-cluster
	color: var(--wite)
	text-decoration: none
	list-style: none

span
	color: var(--wite)

p
	font-family: JostRegular-c
	padding-top: 10px
	padding-bottom: 10px
	text-indent: 30px
	letter-spacing: 1px
	text-align: justify
	text-justify: inter-cluster
	color: antiquewhite

article
	font-family: JostRegular-c
	width: 100%
	padding: 0 20px
	overflow: hidden
	/* offset-x | offset-y | blur-radius | spread-radius | color */
	box-shadow: 0 20px 40px -14px rgba(0, 0, 0, .5)
	//background: var(--blue)
	// border-left: 20px solid var(--yellow)
	// border-top: 10px solid var(--yellow)
	// border-right: 20px solid var(--yellow)
	// border-bottom: 10px solid var(--yellow)
	padding-top: 40px
	padding-bottom: 60px
	:nth-of-type(1)
		//border-top: 20px solid var(--yellow)
	:nth-last-of-type(1)
		//border-bottom: 20px solid var(--yellow)
	h1
		display: block
		font-size: 38px
		color: var(--wite)
		text-align: center
		text-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
		%h1-after-before
			font-size: 48px
			color: var(--wite)
			text-align: center
		::after
			content: " -"
			@extend %h1-after-before

		::before
			content: "- "
			@extend %h1-after-before
	h2
		margin-top: 20px
		font-size: 28px
		color: var(--wite)
		text-align: center
		text-decoration: underline

body
	background-color: var(--black)
	font-style: normal
	font-weight: 400
	letter-spacing: 0
	text-rendering: optimizeLegibility
	min-width: 320px
	background: var(--bg-gradient)
