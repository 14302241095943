*
	margin: 0 
	padding: 0 
	box-sizing: border-box 
	color: white
	::after,
	::before 
		margin: 0 
		padding: 0 
		box-sizing: border-box 
		color: white