.advantage
	width: 100%
	height: 100vh
	padding-left: 0
	padding-right: 0 
	box-shadow: none
	.splide
		height: 100%
		.splide__track
			height: 100%
			padding: 50px 20px
			.splide__list
				.splide__slide
					display: flex
					justify-content: center
					align-items: center
					//border: 1px solid black
					//border-radius: 15px
					box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
					.splide__content__text
						display: flex
						justify-content: center
						align-items: center
						text-align: center
						font-size: 60px
						width: auto
						height: auto
						width: 100%
						height: 100%
						text-shadow: 1px 1px 2px black, 0 0 1em white, 0 0 0.2em white
						line-height: 90%
%slide__bg__before
	content: " "
	display: block
	width: 100%
	height: 100%
	position: absolute
	background-repeat: no-repeat
	background-size: cover
	z-index: -9999
%slide__bg__after
	background-color: rgba(0, 0, 0, 0.4)
	content: " "
	display: block
	width: 100%
	height: 100%
	position: absolute
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	z-index: -9998
.slide__bg__1
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/01.jpg")
.slide__bg__2
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/02.jpg")
.slide__bg__3
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/03.jpg")
.slide__bg__4
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/04.jpg")
.slide__bg__5
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/05.jpg")
.slide__bg__6
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/06.jpg")
.slide__bg__7
	&::after
		@extend %slide__bg__after
	&::before
		@extend %slide__bg__before
		background-image: url("/assets/img/advantage/07.jpg")