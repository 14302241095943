
.vacancies
  $white: black
  $grey: #505050
  $red: var(--yellow)
  $black: var(--wite)
  $sans: "ElMessiri-c"
  background-color: $grey
  margin-top: 60px
  padding-bottom: 0px
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1)
  border-radius: 1rem
  .transition
    transition: all 0.25s ease-in-out
  .flipIn
    animation: flipdown 0.5s ease both
  .no-select
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
  @media ( max-width:550px )
    box-sizing: border-box
    transform: translate( 0 , 0 )
    max-width: 100%
    min-height: 100%
    margin: 0
    left: 0
  .acc__font
    font-family: Verdana, Geneva, Tahoma, sans-serif
    text-align: left
  .acc__text__title
    @extend .acc__font
  .acc__text__text
    @extend .acc__font
    margin: 0
  h1
    color: $black

  h2
    color: $red

  h1
    text-transform: uppercase
    font-size: 36px
    line-height: 42px
    letter-spacing: 3px
    font-weight: 100
    @extend .flipIn
    margin-bottom: 20px

    
  h2
    font-size: 26px
    line-height: 34px
    font-weight: 300
    letter-spacing: 1px
    display: block
    background-color: $grey
    margin: 0
    cursor: pointer
    text-decoration: none
    @extend .no-select

  div
    color: $black
    font-size: 12px
    line-height: 35px
    letter-spacing: 1px
    position: relative
    overflow: hidden
    max-height: 800px
    @extend .transition
    opacity: 1
    transform: translate( 0 , 0 )
    margin-top: 14px
    z-index: 2
  p
    color: $black
    font-size: 18px
    line-height: 1px
    letter-spacing: 0px
    position: relative
    overflow: hidden
    max-height: 800px
    @extend .transition
    opacity: 1
    transform: translate( 0 , 0 )
    margin-top: 14px
    z-index: 2
  b
    font-weight: bold
    font-size: 24px
    
  ul
    list-style: none
    perspective: 900
    padding: 20px 0px
    margin: 0
    
    li
      position: relative
      padding: 0
      margin: 0
      padding-bottom: 4px
      padding-top: 18px
      border-top: 1px dotted $white
      @extend .flipIn
        
      &:nth-of-type(1)
        animation-delay: 0.5s
      
      &:nth-of-type(2)
        animation-delay: 0.75s
          
      &:nth-of-type(3)
        animation-delay: 1.0s
      
      &:last-of-type
        padding-bottom: 0
        
      i
        position: absolute
        transform: translate( -6px , 0 )
        margin-top: 16px
        right: 0
        
        &:before , &:after
          content: ""
          @extend .transition
          position: absolute
          background-color: $red
          width: 3px
          height: 9px
          
        &:before
          transform: translate( -2px , 0 ) rotate( 45deg )
            
        &:after
          transform: translate( 2px , 0 ) rotate( -45deg )
      
      input[type=checkbox]
        position: absolute
        cursor: pointer
        width: 100%
        height: 100%
        z-index: 1
        opacity: 0
        
        &:checked
          &~div
            margin-top: 0
            max-height: 0
            opacity: 0
            transform: translate( 0 , 50% )
              
          &~i
            &:before
              transform: translate( 2px , 0 ) rotate( 45deg )
            
            &:after
              transform: translate( -2px , 0 ) rotate( -45deg )

  @keyframes flipdown
    0%
      opacity: 0
      transform-origin: top center
      transform: rotateX(-90deg)
    5%
      opacity: 1
    80%
      transform: rotateX(8deg)
    83%
      transform: rotateX(6deg)
    92%
      transform: rotateX(-3deg)
    100%
      transform-origin: top center
      transform: rotateX(0deg) 